import { all, takeEvery, put, call } from 'redux-saga/effects';
import { rsf, db } from '@iso/lib/firebase/firebase';
import { isLoggedInUserAContractor, getLoggedInUser } from '@iso/lib/remod-helpers/localStorage';
import { cloneContractor, createNewContractorFromEmail } from '@iso/lib/remod-helpers/globalHelpers';
import { getCurrentUserEmail } from '@iso/lib/firebase/firebase.authentication.util';
import contractorsActions from './actions';
import projectitemsActions from '../projectitems/actions';
import projectPaymentsActions from '../projectPayments/actions';
import emailjs from '@emailjs/browser';
import { PUBLIC_ROUTE } from './../../route.constants';
import { COST_TYPES } from '@iso/lib/remod-helpers/globalEnumsAndConsts';
import { splitArrayIntoMultipleArraysForSagaQuery } from '@iso/lib/remod-helpers/globalHelpers';

function* loadContractorsOfProject({payload}) {
    try {
        let contractors = yield call(prepareContractorsFromProject, payload.projectId);
        const contractorIds = Object.keys(contractors)
                    .map((id) => contractors[id].email);
        let existingUsers = new Map();
        let invitedContractors = [];
        let contractorsWithEstimationLink = [];
        let multipleArrays = splitArrayIntoMultipleArraysForSagaQuery(contractorIds);
        for (let i = 0; i < multipleArrays.length; i++) {
            const usersQueryReference = db
            .collection("users")
            .where('role', '==', 'contractor')
            .where("email", "in", multipleArrays[i]);
            const usersSnapshot = yield call(
                rsf.firestore.getCollection,
                usersQueryReference
            );
            usersSnapshot.forEach((user) => {
                existingUsers.set(user.data().email, user.data());
            });
            const invitedContractorsQueryReference = db
                .collection("links")
                .where('role', '==', 'contractor')
                .where("email", "in", multipleArrays[i]);
            const invitedContractorsSnapshot = yield call(
                rsf.firestore.getCollection,
                invitedContractorsQueryReference
            );
            invitedContractorsSnapshot.forEach((invitedContractor) => {
                invitedContractors.push(invitedContractor.data().email);

            });
            const contractorsWithEstimationLinkQueryReference = db
                .collection("links")
                .where('projectId', '==', payload.projectId)
                .where("email", "in", multipleArrays[i]);
            const contractorsWithEstimationLinkSnapshot = yield call(
                rsf.firestore.getCollection,
                contractorsWithEstimationLinkQueryReference
            );
            contractorsWithEstimationLinkSnapshot.forEach((contractorWithEstimationLink) => {
                contractorsWithEstimationLink.push(contractorWithEstimationLink.data().email);

            });
        }
        Object.keys(contractors).forEach(id => {
            contractors[id] = {
                ...contractors[id],
                name: isLoggedInUserAContractor() ?
                    existingUsers.get(contractors[id].email).firstname + ' ' + existingUsers.get(contractors[id].email).lastname :
                    contractors[id].name,
                userContractorExists: existingUsers.has(contractors[id].email),
                contractorInvited: invitedContractors.includes(contractors[id].email),
                contractorWithEstimationLink: contractorsWithEstimationLink.includes(contractors[id].email)
            };
        });
        yield put(
            contractorsActions.createLoadContractorsOfProjectActionSuccess({
                contractorsList: contractors,
            })
        );
    } catch (error) {
        yield put(
            contractorsActions.createLoadContractorsOfProjectActionFailed({
                contractorsError: error,
            })
        );
    }
}

function* prepareContractorsFromProject(projectId) {
    let contractors = {};
    try {
        const loggedInUserEmail = yield call(getCurrentUserEmail);
        const collectionReference = db
            .collection('contractors')
            .where('projectId', '==', projectId);
        const contractorsSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
        contractorsSnapshot.forEach((contractor) => {
            if (isLoggedInUserAContractor() && contractor.data().email === loggedInUserEmail) {
                contractors[contractor.id] = {
                    ...contractor.data()
                };
            } else {
                contractors[contractor.id] = {
                    ...contractor.data()
                };
            }
        });
        return contractors;
    } catch(error) {
        throw Error(error);
    }
}

function* getContractorByEmail(payload) {
    let contractorResult = undefined;
    const collectionReference = db
        .collection('contractors')
        .where('email', '==', payload.email.toLowerCase());
    const contractorsSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
    contractorsSnapshot.forEach((contractor) => {
        contractorResult = { ...contractor.data(), key: contractor.id };
    });
    return contractorResult;
}

function* createContractorSaga({ payload }) {
    try {
        const existingContractor = yield call(getContractorByEmail, { email: payload.email });
        if (existingContractor && payload.projectId === existingContractor.projectId) {
            yield put(
                contractorsActions.createCreateContractorActionFailed({
                    contractorsError: 'User with given email already exists in project'
                })
            );
            yield put(
                contractorsActions.createCreateContractorActionFailed({
                    contractorsError: ''
                })
            );
            return;
        }
        const userResult = yield call(getUserByEmail, { email: payload.email });
        if (userResult !== undefined && userResult.role === 'owner') {
            yield put(contractorsActions.createEditContractorActionFailed({ contractorsError: 'Contractor cannot be created with given email' }));
            yield put(contractorsActions.createEditContractorActionFailed({ contractorsError: '' }));
            return;
        }
        const loggedInUserEmail = yield call(getCurrentUserEmail);
        payload.createdBy = loggedInUserEmail;
        payload.lastModifiedBy = loggedInUserEmail;
        if (payload.files) {
            const files = [];
            yield all(payload.files
                .filter(item => item.action === 'add')
                .map(item => call(addFile, item.file, files))
            );
            payload.files = files;
        }
        const contractor = cloneContractor(payload);
        const id = db.collection('contractors').doc().id;
        yield call(rsf.firestore.setDocument, 'contractors/' + id, contractor);
        if (payload.sendEstimation) {
            yield call(sendEstimationEmailForContractor, payload);
        }
        yield put(
            contractorsActions.createCreateContractorActionSuccess({
                contractorsSuccess: '',
            })
        );
        yield put(
            contractorsActions.createLoadContractorsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
    } catch (error) {
        yield put(
            contractorsActions.createCreateContractorActionFailed({
                contractorsError: error,
            })
        );
    }
}

function* addFile(file, result) {
    const task = yield call(rsf.storage.uploadFile, "files/" + file.name, file);
    yield task
    const url = yield call(rsf.storage.getDownloadURL, "files/" + file.name);
    const resultUrl = yield call(fetch, url);
    result.push({ 'url': resultUrl.url, 'name': file.name });
};

function* removeFile(file) {
    const task = yield call(rsf.storage.deleteFile, "files/" + file.name);
    yield task
};

function* clearContractorsFlags() {
    yield put(
        contractorsActions.createCreateContractorActionSuccess({
            contractorsSuccess: '',
            contractorsError: '',
        })
    );
}

function* makeContractorComparative({ payload }) {
    try {
        const contractorsQueryReference = db
            .collection("contractors")
            .where('projectId', '==', payload.projectId)
            .where("email", "==", payload.email);
        const contractorsSnapshot = yield call(
            rsf.firestore.getCollection,
            contractorsQueryReference
        );
        const batch = db.batch();
        contractorsSnapshot.forEach((contractor) => {
            const projectItemRef = db.collection('contractors').doc(contractor.id);
            batch.update(projectItemRef, {
                comparative: payload.comparative,
            });
        });
        yield call([batch, batch.commit]);
        yield put(
            contractorsActions.createLoadContractorsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
    } catch (error) {
        yield put(
            contractorsActions.createEditContractorActionFailed({
                contractorsError: error,
            })
        );
    }
}

function* editContractorSaga({ payload }) {
    try {
        const existingContractor = yield call(getContractorByEmail, { email: payload.email });
        if (existingContractor && payload.projectId === existingContractor.projectId) {
            yield put(
                contractorsActions.createCreateContractorActionFailed({
                    contractorsError: 'User with given email already exists in project'
                })
            );
            yield put(
                contractorsActions.createCreateContractorActionFailed({
                    contractorsError: ''
                })
            );
            return;
        }
        const userResult = yield call(getUserByEmail, { email: payload.email });
        if (userResult !== undefined && userResult.role === 'owner') {
            yield put(contractorsActions.createEditContractorActionFailed({ contractorsError: 'Contractor cannot be created with given email' }));
            yield put(contractorsActions.createEditContractorActionFailed({ contractorsError: '' }));
            return;
        }
        const loggedInUserEmail = yield call(getCurrentUserEmail);
        const id = payload.key === 'notAssigned' ? db.collection('contractors').doc().id : payload.key;
        payload.createdBy = payload.createdBy ?? loggedInUserEmail;
        payload.lastModifiedBy = loggedInUserEmail;
        payload.lastModifiedAt = undefined;
        const contractor = cloneContractor(payload);
        yield call(rsf.firestore.setDocument, 'contractors/' + id, contractor);
        yield call(reassignContractorProjectItem, { payload });
        yield call(reassignContractorProjectPayment, { payload });
        yield put(
            contractorsActions.createEditContractorActionSuccess({
                contractorsSuccess: '',
            })
        );
        yield put(
            contractorsActions.createLoadContractorsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
        yield put(
            projectPaymentsActions.createLoadProjectPaymentsStartAction(
                { projectId: payload.projectId }
            )
        );
    } catch (error) {
        yield put(
            contractorsActions.createEditContractorActionFailed({
                contractorsError: error,
            })
        );
    }
}

function* reassignContractorProjectItem({ payload }) {
    const projectItemsQueryReference = db
        .collection("projectitems")
        .where('costType', '==', COST_TYPES.LABOR)
        .where('projectId', '==', payload.projectId)
        .where("contractorId", "==", payload.previousEmail);
    const projectItemsSnapshot = yield call(
        rsf.firestore.getCollection,
        projectItemsQueryReference
    );
    const batch = db.batch();
    projectItemsSnapshot.forEach((projectItem) => {
        const projectItemRef = db.collection('projectitems').doc(projectItem.id);
        batch.update(projectItemRef, {
            contractorId: payload.email,
        });
    });
    yield call([batch, batch.commit]);
}

function* reassignContractorProjectPayment({ payload }) {
    if (payload.previousEmail === '') {
        return;
    }
    const projectPaymentsQueryReference = db
        .collection("payments")
        .where('projectId', '==', payload.projectId)
        .where("contractorId", "==", payload.previousEmail);
    const projectPaymentsSnapshot = yield call(
        rsf.firestore.getCollection,
        projectPaymentsQueryReference
    );
    const batch = db.batch();
    projectPaymentsSnapshot.forEach((projectItem) => {
        const projectPaymentRef = db.collection('payments').doc(projectItem.id);
        batch.update(projectPaymentRef, {
            contractorId: payload.email,
        });
    });
    yield call([batch, batch.commit]);
}

function* deleteContractorSaga({ payload }) {
    try {
        yield call(removeAllItemsInProjectAssignedToContractor, payload);
        yield call(removeAssignedFiles, payload);
        yield call(removeAssigmentToProject, payload);
        const linksToRemove = [];
        const linksQueryReference = db
            .collection("links")
            .where('projectId', '==', payload.projectId)
            .where("email", "==", payload.email);
        const linksSnapshot = yield call(
            rsf.firestore.getCollection,
            linksQueryReference
        );
        linksSnapshot.forEach((link) => {
            linksToRemove.push(link.id);
        });
        yield all(linksToRemove.map(id => call(rsf.firestore.deleteDocument, 'links/' + id)));
        yield put(
            contractorsActions.createDeleteContractorActionSuccess({
                contractorsSuccess: '',
            })
        );
        yield put(
            projectitemsActions.createLoadProjectitemsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
        yield put(
            contractorsActions.createLoadContractorsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
        yield put(
            projectPaymentsActions.createLoadProjectPaymentsStartAction(
                { projectId: payload.projectId }
            )
        );
    } catch (error) {
      yield put(
        contractorsActions.createDeleteContractorActionFailed({
          contractorsError: error,
        })
      );
    }
  }

  function* removeAssigmentToProject(payload) {
    const id = payload.key;
    try {
        yield call(rsf.firestore.deleteDocument, 'contractors/' + id);
    } catch(error) {
        throw Error(error);
    }
  }

function* removeAssignedFiles(payload) {
    const existingContractor = yield call(getContractorByEmail, { email: payload.email });
    yield all(existingContractor.files.map(file => call(removeFile, file)));
}

  function* removeAllProjectItemsInProjectAssignedToContractor(payload) {
    let projectItemIds = [];
    const batch = db.batch();
    const collectionReference = db
        .collection('projectitems')
        .where('projectId', '==', payload.projectId)
        .where('costType', '==', COST_TYPES.LABOR)
        .where('contractorId', '==', payload.email)
    const projectItemsSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
    projectItemsSnapshot.docs.forEach((projectItem) => {
        projectItemIds.push(projectItem.id);
    });
    const projectItemRef = db.collection('projectitems');
    projectItemIds.forEach(projectItemId => {
        batch.delete(projectItemRef.doc(projectItemId));
    });
    yield call([batch, batch.commit]);
    return projectItemIds;
  }

  function* removeAllPaymentsInProjectAssignedToContractor(payload) {
    let paymentIds = [];
    const batch = db.batch();
    let multipleArrays = splitArrayIntoMultipleArraysForSagaQuery(payload.projectItemIds);
    for (let i = 0; i < multipleArrays.length; i++) {
        const queryReference = db
            .collection("payments")
            .where('projectId', '==', payload.projectId)
            .where("projectItemId", "in", multipleArrays[i]);
        const paymentsSnapshot = yield call(
            rsf.firestore.getCollection,
            queryReference
        );
        paymentsSnapshot.forEach((payment) => {
            paymentIds.push(payment.id);
        });
    }
      if (payload.email !== undefined && payload.email !== '') {
          const queryReference = db
              .collection("payments")
              .where('projectId', '==', payload.projectId)
              .where("contractorId", "==", payload.email);
          const paymentsSnapshot = yield call(
              rsf.firestore.getCollection,
              queryReference
          );
          paymentsSnapshot.forEach((payment) => {
              paymentIds.push(payment.id);
          });
      }
    const paymentRef = db.collection('payments');
    paymentIds.forEach(paymentId => {
        batch.delete(paymentRef.doc(paymentId));
    });
    yield call([batch, batch.commit]);
  }

function* removeAllItemsInProjectAssignedToContractor(payload) {
    payload.projectItemIds = [];
    try {
        const projectItemIds = yield call (removeAllProjectItemsInProjectAssignedToContractor, payload);
        if (projectItemIds.length > 0) {
            payload.projectItemIds = projectItemIds; 
        }
        yield call(removeAllPaymentsInProjectAssignedToContractor, payload);
    } catch (error) {
        throw Error(error);
    }
}

function* sendRequestEstimationSaga({ payload }) {
    let emailsToAdd = payload.emails;
    try {
        const batch = db.batch();
        const loggedInUserEmail = yield call(getCurrentUserEmail);
        let multipleArrays = splitArrayIntoMultipleArraysForSagaQuery(payload.emails);
        for (let i = 0; i < multipleArrays.length; i++) {
            const collectionReference = db
                .collection('contractors')
                .where('email', 'in', multipleArrays[i])
                .where('projectId', '==', payload.projectId);
            const contractorSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
            contractorSnapshot.docs.forEach((contractor) => {
                if (payload.emails.includes(contractor.data().email)) {
                    emailsToAdd = emailsToAdd.filter(e => e !== contractor.data().email)
                }
            });
        }
        const contractorsCollectionRef = db.collection('contractors');
        emailsToAdd.forEach((email) => {
            const contractorRef = contractorsCollectionRef.doc();
            const newContractor = createNewContractorFromEmail(
                email,
                payload.projectId,
            );
            batch.set(contractorRef, {
                ...newContractor,
                comparative: true,
                createdBy: loggedInUserEmail,
                lastModifiedBy: loggedInUserEmail
            });
        });
        yield call([batch, batch.commit]);
        const emailsPayload = payload.emails.map(email =>
            ({ email: email, projectName: payload.projectName, projectId: payload.projectId })
        );
        yield all(emailsPayload.map(emailPayload => call(sendEstimationEmailForContractor, emailPayload)));
    } catch (error) {
        yield put(
            contractorsActions.createInviteContractorFailed({
                contractorsError: error,
            })
        );
    }
}

function* findContractorSaga({ payload }) {
    try {
        yield put(
            contractorsActions.createFindContractorSuccess({
                contractorsSuccess: 'Looking for Pros in your area'
            })
        );
    } catch (error) {
        yield put(
            contractorsActions.createFindContractorFailed({
                contractorsError: error,
            })
        );
    }
}

function* inviteContractorSaga({ payload }) {
    let contractorResult = undefined;
    try {
        const collectionReference = db
            .collection('users')
            .where('email', '==', payload.email)
            .where('role', '==', 'contractor');
        const contractorSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
        contractorSnapshot.docs.forEach((contractor) => {
            contractorResult = {
                ...contractorResult,
                [contractor.id]: contractor.data(),
            };
        });
        if (contractorResult === undefined) {
            yield call(sendEmailForContractor, payload);
        }

        yield put(
            contractorsActions.createInviteContractorSuccess({
                contractorsSuccess: 'Contractor has been invited'
            })
        );
        yield put(
            contractorsActions.createInviteContractorSuccess({
                contractorsSuccess: ''
            })
        );
        yield put(
            contractorsActions.createLoadContractorsOfProjectActionStart({
                projectId: payload.projectId,
            })
        );
    } catch (error) {
        yield put(
            contractorsActions.createInviteContractorFailed({
                contractorsError: error,
            })
        );
    }
}

function* getUserByEmail(payload) {
    let userResult = undefined;
    const collectionReference = db
        .collection('users')
        .where('email', '==', payload.email.toLowerCase());
    const usersSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
    usersSnapshot.forEach((user) => {
        userResult = { ...user.data(), key: user.id };
    });
    return userResult;
}

function createLinkUrl(id) {
    const baseHost = process.env.REACT_APP_HOST_URL;
    return baseHost + PUBLIC_ROUTE.SIGN_UP + '/' + id;
}

function* sendEmailForContractor(payload) {
    const loggedInUserEmail = yield call(getCurrentUserEmail);
    const linkSnapshot = yield call(rsf.firestore.addDocument, 'links', {
        email: payload.email,
        role: 'contractor',
    });
    linkSnapshot.get().then((link) => {
        const linkUrl = createLinkUrl(link.id);
        const params = {};
        params['linkUrl'] = linkUrl;
        params['email'] = link.data().email;
        params['inviterEmail'] = loggedInUserEmail;
        emailjs
            .send(
                process.env.REACT_APP_EMAIL_SERVICE_ID,
                process.env.REACT_APP_EMAIL_INVITATION_TEMPLATE_ID,
                params,
                process.env.REACT_APP_EMAIL_API_KEY
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    throw Error('Error with sending email invitation');
                }
            );
    });
}

function* sendEstimationEmailForContractor(payload) {
    const currentUser = getLoggedInUser();
    const linkSnapshot = yield call(rsf.firestore.addDocument, 'links', {
        email: payload.email,
        projectId: payload.projectId
    });
    linkSnapshot.get().then((link) => {
        const linkUrl = createEstimationLinkUrl(link.id);
        const params = {};
        params['linkUrl'] = linkUrl;
        params['email'] = payload.email;
        params['homeOwnerFirstName'] = currentUser.firstname;
        params['homeOwnerLastName'] = currentUser.lastname;
        params['projectName'] = payload.projectName;
        emailjs
            .send(
                process.env.REACT_APP_EMAIL_SERVICE_ID,
                process.env.REACT_APP_EMAIL_ESTIMATION_TEMPLATE_ID,
                params,
                process.env.REACT_APP_EMAIL_API_KEY
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    throw Error('Error with sending email invitation');
                }
            );
    });
}

function createEstimationLinkUrl(id) {
    const baseHost = process.env.REACT_APP_HOST_URL;
    return baseHost + PUBLIC_ROUTE.ESTIMATION + '/' + id;
}


export default function* contractorsSaga() {
    yield all([
        takeEvery(contractorsActions.LOAD_CONTRACTORS_OF_PROJECT_START, loadContractorsOfProject),
        takeEvery(contractorsActions.CREATE_CONTRACTOR_START, createContractorSaga),
        takeEvery(contractorsActions.EDIT_CONTRACTOR_START, editContractorSaga),
        takeEvery(contractorsActions.DELETE_CONTRACTOR_START, deleteContractorSaga),
        takeEvery(contractorsActions.CLEAR_CONTRACTORS_FLAGS, clearContractorsFlags),
        takeEvery(contractorsActions.INVITE_CONTRACTOR_START, inviteContractorSaga),
        takeEvery(contractorsActions.SEND_REQUEST_ESTIMATION_START, sendRequestEstimationSaga),
        takeEvery(contractorsActions.MAKE_COMPARATIVE_CONTRACTOR_START, makeContractorComparative),
        takeEvery(contractorsActions.FIND_CONTRACTOR_START, findContractorSaga)
    ]);
}
