export const PAYMENT_STATUSES = {
    PAID: 'paid',
    PENDING: 'pending',
};

export const PAYMENT_PAID = {
    TRUE: 'true',
    FALSE: 'false'
}

export const FIELD_TYPES_DEFINITION = {
    TEXT: 'text',
    TEXT_AREA: 'textArea',
    NUMBER: 'number',
    DATE: 'date',
    SWITCH: 'switch',
    SELECT: 'select',
    FLEXIBLE_SELECT: 'flexibleSelect',
    COST_STRUCTURE: 'costStructure',
    TAG_SELECT: 'tagSelect',
    RADIO: 'radio',
    FILE_UPLOAD: 'fileUpload'
};

export const CATEGORY_TYPES = {
    CONTRACTORS: 'contractors',
    OUT_OF_POCKET: 'out-of-pocket'
}

export const PROJECT_TABS = {
    PLANNED_BUDGET: 'planned-budget',
    PAYMENTS_MADE: 'payments-made',
    PROJECT_INFO: 'project-info',
    ESTIMATES_COMPARISON: 'estimates-comparison'
}

const PROJECT_STATUSES = [
    { label: 'Proposal', value: 'proposal' },
    { label: 'Proposal-sent', value: 'proposal-sent' },
    { label: 'Execution', value: 'execution' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
];

const PROJECT_STATUSES_FOR_HO_DESCRIPTION = {
    proposal: 'Designing, budgeting and selecting contractor',
    execution: 'Contractor selected, construction and installation started',
};

const STANDARDS = [
    { label: 'Basic/Standard', value: 'LOW' },
    { label: 'Mid-Range', value: 'MEDIUM' },
    { label: 'High-End/Luxury', value: 'HIGH' },
];

const STANDARDS_DESCRIPTION = {
    LOW: 'This level includes standard or entry-level finishes that are more budget-friendly. It typically encompasses basic materials, fixtures, and appliances with minimal customization or high-end features.',
    MEDIUM: 'This level offers a balance between cost and quality. It involves upgrading materials, fixtures, and appliances to a mid-range standard, providing improved aesthetics and functionality compared to the basic level.',
    HIGH: 'This level represents premium or luxury finishes, materials, fixtures, and appliances. It includes high-quality, upscale options, often customized or tailored to specific design preferences, providing a luxurious and sophisticated look and feel.',
};

const QUOTE_CONTACTS = [
    { label: 'I will upload the quotes', value: 'QUOTES' },
    { label: 'Help me find a contractor', value: 'FIND_CONTRACTOR' }
];

const QUOTE_CONTACTS_DESCRITPION = {
    QUOTES: 'Simply upload your contractor quotes by clicking on \'New Quote\' from the \'Quote Comparison\' tab and we’ll Standardize them for you',
    FIND_CONTRACTOR: 'We will search in our database for qualified contractors in your area and request quotes based on our detailed cost breakdown'
}

const PROJECT_PURPOSES = [
    'Estimate cost of my renovation',
    'Find a contractor',
    'Compare and select contractors',
    'Track costs for my renovation',
    'Manage timelines of my renovation',
    'Better communicate with my contractor',
    'Other',
];

export const COST_TYPES = {
    MATERIALS: 'materials',
    LABOR: 'labor',
};

export const DEFAULT_CATEGORY = 'Other';

export const COST_TYPE_LABELS = [
    { label: 'Out-of-pocket', value: COST_TYPES.MATERIALS },
    { label: 'Contractor', value: COST_TYPES.LABOR },
];

export const getAllPaymentStatuses = () => {
    return [PAYMENT_STATUSES.PAID, PAYMENT_STATUSES.PENDING];
}

export const getAllPaidCheckboxes = () => {
    return [PAYMENT_PAID.TRUE, PAYMENT_PAID.FALSE];
}

export const getInitialProjectStatus = () => {
    return PROJECT_STATUSES[0].value;
};

export const getProjectStatusesForEmailHO = () => {
    return PROJECT_STATUSES.filter((x) => ['proposal-sent', 'execution'].includes(x.value)).map(
        (x) => x.value
    );
};

export const getProjectStatuses = () => {
    return PROJECT_STATUSES;
};

export const getLabelProjectStatuses = () => {
    return PROJECT_STATUSES.map((x) => x.label);
};

export const getProjectStandards = () => {
    return STANDARDS;
}

export const getInitialProjectStandard = () => {
    return STANDARDS[0].value;
}

export const getDescriptionBasedOnStandard = (standard) => {
    return STANDARDS_DESCRIPTION[standard];
}

export const getQuoteContacts = () => {
    return QUOTE_CONTACTS;
}

export const getInitialQuoteContact = () => {
    return QUOTE_CONTACTS[0].value;
};

export const getDescriptionBasedOnQuoteContact = (quoteContact) => {
    return QUOTE_CONTACTS_DESCRITPION[quoteContact];
}

export const getHOStatusDescriptionBasedOnStatus = (status) => {
    return PROJECT_STATUSES_FOR_HO_DESCRIPTION[status];
}

export const getProjectPurposes = () => {
    return PROJECT_PURPOSES;
}

export const getInitialProjectPurpose = () => {
    return PROJECT_PURPOSES[0];
}

export const defaultOwnerEmailAddress = 'draftProject@remodelum.com';
export const automaticEstimationEmailAddress = 'info@remodelum.com';
